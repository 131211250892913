import React from 'react';

import './style/App.less';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

// Fonts
import './fonts/Euphemia-UCAS-Bold.ttf';

// Pages
import About from './pages/About';
import Csr from './pages/Csr';
import HomePage from './pages/HomePage';
import HotelsList from './pages/HotelsList';
import Services from './pages/Services';
import HotelDetails from './pages/HotelDetails';
import NotFound from './pages/404';
import contact from './pages/contact';
import ScrollToTop from './components/ScrollToTop';
import RedirectHotels from './components/RedirectHotels';
import Hoteliers from './pages/Hoteliers';

// Helpers
import RedirectBlog from './components/RedirectBlog';
import RedirectFeaturedHotels from './components/RedirectFeaturedHotels';
import Groups from './pages/Groups';

import { QueryClient, QueryClientProvider } from 'react-query';

// const trackingId = "UA-62758810-1"; // Replace with your Google Analytics tracking ID
// ReactGA.initialize(trackingId);
// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })

// Create a client
const queryClient = new QueryClient();

const App = () => {
  const { pathname } = useLocation();
  if (pathname !== '/' && pathname.endsWith('/')) {
    return <Redirect to={pathname.slice(0, -1)} status={301} />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ScrollToTop />
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/about' component={About} />
        <Route exact path='/csr' component={Csr} />
        <Route exact path='/contact' component={contact} />
        <Route exact path='/featured-hotels' component={HotelsList} />
        <Route
          exact
          path='/featured-hotels/:categoryId'
          component={HotelsList}
        />
        <Route
          exact
          path='/featured-hotels/location/:location'
          component={HotelsList}
        />
        <Route path='/featured-hotel/:id' component={HotelDetails} />
        {/* <Route exact path='/services' component={Services} /> */}
        <Route exact path='/hoteliers' component={Hoteliers} />
        <Route path='/news' component={RedirectBlog} />
        <Route path='/groups' component={Groups} />
        <Route path='/luxlyfe' component={RedirectBlog} />
        <Route path='/blog/:slug' component={RedirectBlog} />
        <Route path='/blog' component={RedirectBlog} />
        <Route path='/featured/:id' component={RedirectFeaturedHotels} />
        <Redirect from='/rfp' to='/contact' />
        <Redirect from='/partner' to='/hoteliers' />
        <Redirect from='/search' to='/' />
        <Redirect from='/team' to='/about' />
        <Route path='/404' component={NotFound} />
        <Route path='/:id' component={RedirectHotels} />
        <Redirect to='/404' />
      </Switch>
    </QueryClientProvider>
  );
};

export default App;
