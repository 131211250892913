import React from 'react';
import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { useQuery } from 'react-query';

import '../style/hoteliers.less';

// Components imports
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import SEO from '../components/SEO';
import { FaEnvelope } from 'react-icons/fa';
import { Divider } from 'antd';

const Hoteliers = () => {
  const Client = Prismic.client(apiEndpoint);

  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at('document.type', 'images')
    );

    return response.results[0].data.body[0].items;
  };

  const { isLoading, isError, data, error } = useQuery('hoteliers', fetchData);

  console.log(data);

  return (
    <>
      <SEO
        title='Hoteliers Submit Independent Hotels to Become a Member | Luxpitality'
        description='Visit Luxpitality if you are a hotelier looking for marketing and sales services and solutions for your independent lifestyle hotel. Become a member today.'
        keywords={'become a member'}
      />
      <NavigationBar />
      <div
        style={{
          background: `url(${data?.[31]?.gallery_image?.url}) `,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
          height: '70vh',
          position: 'relative',
        }}
        className='hoteliers-banner'
      >
        <div>
          <h1>Hoteliers</h1>
          <h2>Ready to partner up?</h2>
        </div>
      </div>

      <div className='hoteliers-box'>
        <div className='hoteliers'>
          <div className='hoteliersContainer'>
            <h2 className='hoteliers-subheading'>About</h2>
            <h2 className='hoteliers-heading'>Our members at Luxpitality</h2>
            <p className='hoteliersContent'>
              At Luxpitality we partner with unique hotels that radiate design,
              culture, local roots, and the story that so passionately drove
              them to be unique. The culmination of these stories creates the
              unique Luxpitality brand of hotels.<br></br>
              <br></br>
              Our member hotels don’t just tell a story but exude the
              entrepreneurial spirit to be different, forward-thinking,
              progressive trailblazers that shape the future of hospitality. 
              <br></br>
              <br></br>
              When choosing a hotel to join the Luxpitality portfolio we look
              for several factors including its locally-rooted culture,
              attention to design and detail, passion for culinary artistry, and
              its entrepreneurial character traits. 
              <br></br>
              <br></br>
              The Luxpitality difference is that through the power of our brand,
              technology, sales, and marketing we match unique hotels and
              retreats with new-age travelers and event and meeting planners.
              For information on how to be part of the Luxpitality collection of
              unique hotels, please contact us.<br></br>
            </p>
          </div>

          <div className='hoteliersContainer'>
            <div>
              <h2 className='hoteliers-subheading'>Partenering</h2>
              <h2 className='hoteliers-heading'>The Selection Process</h2>
            </div>

            <p className='hoteliersContent'>
              Our wanderers, leisure, and business travelers trust us more than
              anything. We carefully curate and select only the best independent
              lifestyle hotels and unique retreats around the world and we make
              booking them a journey. To guarantee satisfaction for our clients,
              we ensure that every hotel featured on Luxpitality is first
              evaluated and approved by our team. This process consists of:
              <br />
              <br />
              <ul>
                <li>Sharing your hotel story</li>
                <li>Telling us how you are locally rooted</li>
                <li>
                  Are you practicing sustainable travel and if so please explain
                  how
                </li>
                <li>Highlighting your culinary artistry</li>
              </ul>
              <br />
              Once a hotel is selected and invited for inclusion, one of our
              team members will reach out to you with the next steps. The hotel
              or brand will pay an annual fee along with a commission per
              booking. There is no one size fits all so, Luxpitality will work
              with you in order to make our partnership mutually beneficial.
              <br></br>
              <br></br>
              <br></br>
            </p>
            <button className='hoteliers-button'>
              <a href='mailto:info@luxpitality.com'>
                Contact Us
                <FaEnvelope style={{ marginLeft: '16px' }} />
              </a>
            </button>
          </div>
        </div>
        <div
          className='aboutSeparator hoteliers-separator'
          style={{
            alignItems: 'flex-start',
            justifyContent: 'initial',
          }}
        >
          <div
            className='separator-w-square-about'
            style={{ marginLeft: '5rem' }}
          >
            <Divider type='vertical' className='separator-vertical-hoteliers' />
            <div className='squareAbout' style={{ marginTop: '12px' }}></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Hoteliers;
