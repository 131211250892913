import React from 'react';
import { Row, Col, Divider, Tabs, Spin } from 'antd';
import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { useQuery } from 'react-query';

// Components imports
import SEO from '../components/SEO';
import NavigationBar from '../components/NavigationBar';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

// Styles imports
import '../style/App.less';
import '../style/csr.less';
import '../style/HomePage.less';

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

const Csr = () => {
  const Client = Prismic.client(apiEndpoint);

  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at('document.type', 'images')
    );

    return response.results[0].data.body[0].items;
  };

  const { isLoading, isError, data, error } = useQuery('homepage', fetchData);

  return (
    <div className='containerCsr'>
      {isLoading ? (
        <>
          <Spin size='large' tip='Loading...' />
        </>
      ) : (
        <>
          <SEO
            title='Sustainable Hotels and Travel with myclimate Partner | Luxpitality'
            description="Luxpitality is passionate about social responsibility and sustainable hotels and travel. We've partnered with myclimate to help impact the carbon footprint."
            keywords={'sustainable hotels, sustainable hotel'}
          />
          <div
            style={{
              background: `url(${data[19].gallery_image.url})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center',
              backgroundSize: 'cover',
              minHeight: '100vh',
              position: 'relative',
            }}
          >
            <header>
              <NavigationBar />
            </header>

            <Col
              xs={24}
              sm={24}
              md={24}
              lg={20}
              xl={13}
              xxl={13}
              className='csrHeadlineSection'
            >
              <Row>
                <div className='topContainer'>
                  <h4 className='subheadline'>We're Giving Back!</h4>
                  <h2 className='headline'>Preserve Our World</h2>
                  <h5>
                    Social responsibility and sustainable travel means
                    everything to us. It’s why we’ve partnered with myclimate to
                    help offset the carbon footprint of every single traveler
                    and hotel member.
                  </h5>
                  <div className='bottomContainer'>
                    <img src={data[24].gallery_image.url} alt='climatelogo' />
                    <a href='https://www.myclimate.org/'>
                      <h4>
                        View Programs <FontAwesomeIcon icon={faArrowRight} />
                      </h4>
                    </a>
                  </div>
                </div>
              </Row>
            </Col>
          </div>

          <div>
            <Row justify='space-between'>
              <Col
                xs={22}
                sm={22}
                md={22}
                lg={20}
                xl={14}
                xxl={14}
                className='secondSection'
              >
                <div className='textSection'>
                  <h2>Make an Impact With Your Travel</h2>
                  <p>
                    It’s the mission for Swiss-based nonprofit myclimate to
                    promote quantifiable climate protection and long-​lasting
                    development worldwide. Through the many projects they
                    undertake in partnerships with companies both local and
                    global, they’re able to reduce emissions, replace fossil
                    fuel sources with renewable energies, and implement
                    energy-​efficient technologies.
                  </p>
                  <p>
                    Additionally, myclimate offers consulting services on
                    integrated climate protection through analyses, IT
                    solutions, labels, and resource management. Their offerings
                    include everything from simple carbon footprints for
                    businesses to sophisticated product life cycle assessments
                    and performance management.
                  </p>
                  <p>
                    They’ve also built a global network of students,
                    apprentices, young professionals, and business leaders
                    through interactive and action-oriented educational programs
                    that encourage everyone to take action and preserve our
                    planet.
                  </p>
                  <p>
                    Luxpitality is proud to stand alongside myclimate as a
                    partner. Together, we’re confident we can preserve the
                    natural beauty and overall health of our world. Will you
                    join us?
                  </p>
                </div>
              </Col>
              <Col xs={22} sm={22} md={22} lg={24} xl={10} xxl={8}>
                <div className='secondImage'>
                  <img
                    src={data[15].gallery_image.url}
                    className='penguinsImg'
                    alt='penguins'
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className='csrCalculatorSec'>
            <Col span={9} push={1}>
              <div className='separator-w-square-csr'>
                <Divider
                  type='vertical'
                  className='separator-vertical-csr-left'
                />
                <div className='squareCsr' />
              </div>
            </Col>
            <Col span={7}>
              <h4>How Much Will You Save?</h4>
              <p>
                Use our carbon footprint calculator to find out how much carbon
                emission you’ll remove from the Earth’s atmosphere when you
                travel or partner with Luxpitality.
              </p>
            </Col>
            <Col span={9} pull={1}>
              <div className='separator-w-square-csr'>
                <Divider
                  type='vertical'
                  className='separator-vertical-csr-right'
                />
                <div className='squareCsr' />
              </div>
            </Col>
          </div>

          <div className='caseStudies'>
            <Row style={{ width: '100%' }}>
              <Col
                xs={{ span: 20 }}
                sm={20}
                md={20}
                lg={14}
                xl={14}
                xxl={14}
                offset={2}
              >
                <h2>See myclimate in action</h2>
                <p>
                  See how myclimate makes a positive, global impact and learn
                  <br /> how your travel makes a tangible impact to preserve our
                  world.
                </p>
                <Tabs defaultActiveKey='1' onChange={callback} className='tabs'>
                  <TabPane tab='Rwanda Efficient Cook Stoves' key='1'>
                    <p>
                      A reduction in the use of firewood means habitat for
                      animals, less deforestation, reduction in greenhouse gases
                      being released into the atmosphere, more time and money,
                      and improved health for women and girls in Rwanda! This
                      can be achieved with the subsidised sale of energy
                      efficient cook stoves to families around the Volcanoes
                      National Park in North-​West Rwanda.<br></br>
                    </p>

                    <div className='video-responsive'>
                      <iframe
                        title='myclimate-solutions'
                        width='560'
                        height='315'
                        src='https://www.youtube.com/embed/fTFvClHss_g'
                        frameborder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen
                      ></iframe>
                    </div>
                  </TabPane>
                  <TabPane tab='Mangrove Myanmar' key='2'>
                    <p>
                      The project engages the local population of a coastal
                      region in Myanmar to restore degraded mangrove ecosystems.
                      The aim is to create healthy mangrove forests that take up
                      carbon, protect the population from natural disasters and
                      conserve biodiversity by representing a key habitat for
                      endangered species. At the same time, the project aims at
                      diversifying livelihoods of local communities and
                      increasing their well-​being.
                    </p>
                    <div className='video-responsive'>
                      <iframe
                        title='myclimate-solutions'
                        width='560'
                        height='315'
                        src='https://www.youtube.com/embed/lWnJfdD6_4k'
                        frameborder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen
                      ></iframe>
                    </div>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </div>
          <div>
            <Subscribe />
          </div>
          <div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default Csr;
