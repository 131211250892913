import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import NotFound from '../pages/404';

const redirects = {
  'hotel-triton': 'hotel-triton',
  'pinehurst-resort': 'pinehurst-resort',
  'media-rotana': 'media-rotana',
  'regent-beijing': 'regent-beijing',
  'melia-hanoi': 'melia-hanoi',
  'melia-ria': 'melia-ria',
  'melia-lebreros': 'melia-lebreros',
  'meli-milano': 'meli-milano',
  'melia-atlanterra': 'melia-atlanterra',
  'melia-vienna': 'melia-vienna',
  'melia-danang': 'melia-danang',
  'the-broadmoor': 'the-broadmoor',
  'melia-coral': 'melia-coral',
  'melia-campione': 'melia-campione',
  'raffaello-hotel': 'raffaello-hotel',
  'melia-villaitana': 'melia-villaitana',
  'hammock-beach-resort': 'hammock-beach-resort',
  'movenpick-hotel-jeddah': 'movenpick-hotel-jeddah',
  'montage-palmetto-bluff': 'montage-palmetto-bluff',
  'melia-madrid-princesa': 'melia-madrid-princesa',
  'hotel-nikko-niigata': 'hotel-nikko-niigata',
  'kimpton-onyx-hotel': 'kimpton-onyx-hotel',
  'meritage-napa-valley': 'meritage-napa-valley',
  'hotel-nikko-dalian': 'hotel-nikko-dalian',
  'warwick-allerton-chicago': 'warwick-allerton-chicago',
  'eaglewood-resort-spa': 'eaglewood-resort-spa',
  'palazzo-versace-dubai': 'palazzo-versace-dubai',
  'thistle-hyde-park': 'thistle-hyde-park',
  'gran-melia-caracas': 'gran-melia-caracas',
  'madinah-movenpick-hotel': 'madinah-movenpick-hotel',
  'movenpick-resort-hurghada': 'movenpick-resort-hurghada',
  'alila-solo-java': 'alila-solo-java',
  'hotel-schweizerhof-luzern': 'hotel-schweizerhof-luzern',
  'melia-sierra-nevada': 'melia-sierra-nevada',
  'innside-dusseldorf-hafen': 'innside-dusseldorf-hafen',
  'kimpton-surfcomber-hotel': 'kimpton-surfcomber-hotel',
  'bisha-hotel-toronto': 'bisha-hotel-toronto',
  'hotel-nikko-bangkok': 'hotel-nikko-bangkok',
  'thistle-johor-bahru': 'thistle-johor-bahru',
  'parador-de-zamora': 'parador-de-zamora',
  'kimpton-riverplace-hotel': 'kimpton-riverplace-hotel',
  'kimpton-rouge-hotel': 'kimpton-rouge-hotel',
  'movenpick-hotel-riyadh': 'movenpick-hotel-riyadh',
  'melia-marbella-banus': 'melia-marbella-banus',
  'parador-de-plasencia': 'parador-de-plasencia',
  'melia-panama-canal': 'melia-panama-canal',
  'movenpick-hotel-sanaa': 'movenpick-hotel-sanaa',
  'melia-istrian-villas': 'melia-istrian-villas',
  'parador-de-zafra': 'parador-de-zafra',
  'hotel-nikko-guam': 'hotel-nikko-guam',
  'movenpick-hotel-kuwait': 'movenpick-hotel-kuwait',
  'kimpton-shorebreak-hotel': 'kimpton-shorebreak-hotel',
  'centro-tourotel-mariahilf': 'centro-tourotel-mariahilf',
  'melia-test-hotel': 'melia-test-hotel',
  'the-kimpton-brice-hotel': 'the-kimpton-brice-hotel',
  'mikel-statonmissionicon-luxpitality': 'mikel-statonmissionicon-luxpitality',
  'mikel-statonfeaturedbrands-mgallery': 'mikel-statonfeaturedbrands-mgallery',
};

const RedirectHotels = (props) => {
  const { id } = props.match.params;
  const redirectId = redirects[id];

  if (redirectId) {
    return <Redirect to={'/featured-hotels'} />;
  }
  return <Redirect to='/404' />;
};

export default RedirectHotels;
