import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { useHistory } from 'react-router-dom';

// components
import DrawerMenu from './DrawerMenu';

// Styles
import '../style/NavigationBar.less';
import '../style/SearchNavigationBar.less';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMapPin, faSearch } from '@fortawesome/free-solid-svg-icons';

// Images & SVG
import LuxpitalityLogo from '../images/luxpitality-white.png';
import LuxpitalityLogo2 from '../images/luxpitality-blend.png';
import { Select } from 'antd';

const NavigationBar = ({ search }) => {
  const { Option } = Select;
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [location, setLocation] = useState('');
  let history = useHistory();

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const Client = Prismic.client(apiEndpoint);
  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at('document.type', 'location'),
      {
        pageSize: 100,
        orderings: '[document.first_publication_date desc]',
      }
    );
    return response.results;
  };

  const { isLoading, isError, data, error } = useQuery(
    'hotelSelector',
    fetchData
  );

  const onClickHandler = () => {
    if (location) {
      window.location.href = `/featured-hotels/location/${location}`;
    }
  };

  return (
    <>
      {search ? (
        <>
          <section className='search-navigation-bar'>
            <Link to='/'>
              <div className='search-navigation-bar__logo'>
                <img src={LuxpitalityLogo2} alt='Logo' />
              </div>
            </Link>

            <div className='booking-search-location'>
              <div className='booking-search-location-box'>
                <FontAwesomeIcon icon={faMapPin} />

                {!isLoading && (
                  <Select
                    placeholder='Where are we headed?'
                    onChange={(event) => setLocation(event)}
                  >
                    {data?.map((x) => {
                      return (
                        <Option key={x.id} value={x?.slugs[0]}>
                          {x?.data?.title[0]?.text}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </div>
              <button
                className='booking-search-button'
                onClick={onClickHandler}
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>

            <div className='menu-icon'>
              <FontAwesomeIcon icon={faBars} onClick={showDrawer} />
              <DrawerMenu
                visibleDrawer={visibleDrawer}
                setVisibleDrawer={setVisibleDrawer}
              />
            </div>
          </section>
        </>
      ) : (
        <>
          <section className='navigation-bar'>
            <Link to='/'>
              <div className='navigation-bar__logo'>
                <img src={LuxpitalityLogo} alt='Logo' />
              </div>
            </Link>

            <nav className='navigation-links'>
              <span>
                <a
                  href='https://groupbookings.luxpitality.com'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Book an Event
                </a>
              </span>
              <span>
                <a href='/featured-hotels'>Book a Getaway</a>
              </span>
            </nav>
            <span className='menu-icon'>
              <FontAwesomeIcon icon={faBars} onClick={showDrawer} />
              <DrawerMenu
                visibleDrawer={visibleDrawer}
                setVisibleDrawer={setVisibleDrawer}
              />
            </span>
          </section>
        </>
      )}
    </>
  );
};

export default NavigationBar;
