import React, { useState } from 'react';

import { Spin, Divider } from 'antd';

import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

// Components imports
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

// Styles imports
import '../style/App.less';
import '../style/Groups.less';
import '../style/HotelDetails.less';

import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';

const Groups = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const service_id = process.env.REACT_APP_EMAIL_JS_SERVICE_ID;
  const template_id = process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID;
  const user_id = process.env.REACT_APP_EMAIL_JS_USER_ID;

  const Client = Prismic.client(apiEndpoint);

  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at('document.type', 'images')
    );

    return response.results[0].data.body[0].items;
  };

  const { isLoading, data } = useQuery('groups', fetchData);
  const { register, handleSubmit, watch, errors } = useForm();

  const sendEmail = (service_id, template_id, values, user_id) => {
    console.log('sending');
    setSubmitting(true);
    emailjs
      .send(service_id, template_id, values, user_id)
      .then((res) => {
        console.log('Email successfully sent!');
        setSubmitting(false);
        setEmailSent(true);
      })
      .catch((err) =>
        console.error(
          'There has been an error.  Here some thoughts on the error that occured:',
          err
        )
      );
  };

  const onSubmit = (values, r) => {
    r.preventDefault();
    sendEmail(service_id, template_id, values, user_id);
    r.target.reset();
  };

  return (
    <>
      {isLoading ? (
        <>
          <Spin size='large' tip='Loading...' />
        </>
      ) : (
        <>
          <Helmet>
            <title>
              Incentive Travel, Work Retreats, Meeting and Group Hotels |
              Luxpitality
            </title>
            <meta
              name='description'
              content='Contact Luxpitality to help you plan your next work retreat. Book
              incentive travel and group hotel reservations for your next
              business meeting, online today.'
            />
            <meta
              name='keywords'
              content='group travel, book group hotel, group hotels, work retreat, incentive travel, meeting hotels'
            />

            <link rel='canonical' href='https://luxpitality.com/groups' />
          </Helmet>
          <NavigationBar />
          <div>
            <div className='groups-hero'>
              <img
                src={data[30].gallery_image.url}
                className='groups-hero-bg'
                // alt=''
              />
              <div className='groups-hero-box'>
                <h4 className='groups-hero-subtitle'>Our Solution</h4>
                <h1 className='groups-hero-heading'>
                  connecting unique hotels and retreats with trailblazing event
                  planners
                </h1>
              </div>
            </div>
            <div className='groups-form-container'>
              <div className='groups-box-info groups-info '>
                <h4>Group Bookings</h4>
                <h2 className='groups-box-heading'>Who are we?</h2>
                <p>
                  Luxpitality is a unique collection of boutique hotels and
                  retreats. We connect these cool places to event planners
                  looking for venues where groups can celebrate a milestone,
                  recharge, rethink, rediscover, rejuvenate and reconnect or……
                  disconnect from it all. <br />
                  <br />
                  Whether you plan a seminar, conference, team-building session,
                  board-meeting, yoga retreat, wedding, sales meeting or
                  reunion, we have the perfect place for you. <br />
                  <br />
                  We carefully curate and select only the best independent
                  lifestyle hotels and unique retreats and we make booking them
                  easy and fun. To guarantee satisfaction for our event and
                  meeting planners, we ensure that every unique hotel and
                  retreat featured on Luxpitality is locally rooted and
                  practices sustainable travel. Another key element of the
                  Luxpitality collection is the culinary artistry. 
                  <br />
                  <br /> We have done most of the work for you, we have
                  pre-selected the venues. Just fill out the RFP and we will
                  connect you with the coolest hotels. 
                </p>
                <a
                  className='groups-box-link '
                  href='https://groupbookings.luxpitality.com'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <span className='square square--big'></span>
                  Get Started
                </a>
              </div>
              <div className='groups-box-info groups-box-form'>
                <form className='groups-form' onSubmit={handleSubmit(onSubmit)}>
                  <h5>Request for Proposal</h5>
                  <input
                    placeholder='Name'
                    name='from_name'
                    type='text'
                    ref={register({ required: true })}
                  />
                  <input
                    placeholder='Location'
                    name='location'
                    type='text'
                    ref={register({ required: true })}
                  />
                  <input
                    placeholder='Email'
                    name='email'
                    type='email'
                    ref={register({ required: true })}
                  />
                  <div className='groups-form-sublevel'>
                    <input
                      placeholder='# of Rooms'
                      name='rooms'
                      type='text'
                      ref={register({ required: true })}
                    />
                    <input
                      placeholder='# of Event Spaces'
                      name='event_spaces'
                      type='text'
                      ref={register({ required: true })}
                    />
                  </div>
                  <button
                    disabled={submitting}
                    type='submit'
                    className='groups-form-button'
                  >
                    Submit
                  </button>
                  {emailSent && (
                    <span style={{ marginTop: '12px' }}>
                      Email successfully sent!
                    </span>
                  )}
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Groups;
