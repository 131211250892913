import React from 'react';
import { Divider } from 'antd';

// Styles
import '../style/SearchNavigationBar.less';

// Components
import NavigationBar from '../components/NavigationBar';

class NotFoundPage extends React.Component {
  render() {
    return (
      <>
        <div className="not-found">
          <NavigationBar />
          <div className="separator-w-square">
            <Divider type="vertical" className="separator-vertical" />
            <span className="square"></span>
          </div>
          <div className="not-found__main-content">
            <span className="title">404</span>
            <p>Not all who wander are lost</p>
          </div>
        </div>
      </>
    );
  }
}

export default NotFoundPage;