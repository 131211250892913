import React from 'react';
import { Drawer, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { useQuery } from 'react-query';
// Styles
import '../style/NavigationBar.less';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faInstagram,
  faTwitter,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';

// Images & SVG
import LuxpitalityLogo from '../images/luxpitality-white.png';

const DrawerMenu = ({ visibleDrawer, setVisibleDrawer }) => {
  const Client = Prismic.client(apiEndpoint);

  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at('document.type', 'images')
    );

    return response.results[0].data.body[0].items;
  };

  const { isLoading, data } = useQuery('modal', fetchData);

  const onClose = () => {
    setVisibleDrawer(false);
  };

  return (
    <div>
      {isLoading ? (
        <></>
      ) : (
        <>
          <Drawer
            className='navigation-bar__drawer'
            width='auto'
            onClose={onClose}
            visible={visibleDrawer}
            drawerStyle={{
              background: `url(${data[9]?.gallery_image?.url}) #867f6a `,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              maxWidth: '100%',
            }}
          >
            <div className='lux-navigation-content'>
              <div className='lux-navigation-content__logo'>
                <img src={LuxpitalityLogo} alt='Logo' />
              </div>
              <div className='separator-w-square'>
                <Divider type='vertical' className='separator-vertical' />
                <span className='square'></span>
              </div>
              <div className='lux-navigation-content__main-links'>
                <div>
                  <Link to='/about'>About Us</Link>
                  <Link to='/contact'>Contact Us</Link>
                </div>
                <div>
                  <a
                    href='https://groupbookings.luxpitality.com'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Group & Event Hotels
                  </a>
                  <Link to='/featured-hotels'>Getaway Hotels</Link>
                </div>
                <div>
                  <Link to='/groups'>Meeting & Event Planners</Link>
                  <a
                    href='https://blog.luxpitality.com'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Luxlyfe Blog
                  </a>
                </div>
              </div>
              <div className='lux-navigation-content__social-links'>
                <a
                  href='https://www.instagram.com/luxpitality'
                  rel='noopener noreferrer'
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href='https://twitter.com/luxpitality'
                  rel='noopener noreferrer'
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href='https://www.facebook.com/luxpitality'
                  rel='noopener noreferrer'
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              </div>
            </div>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default DrawerMenu;
