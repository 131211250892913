import React, { useState } from 'react';
import { Row, Col, Divider, Carousel, Modal, Button, Spin } from 'antd';
import {
  motion,
  useAnimation,
  useTransform,
  useViewportScroll,
} from 'framer-motion';
import { InView } from 'react-intersection-observer';
import useMediaQuery from 'react-use-media-query-hook';
import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Link } from 'react-router-dom';

import {
  container,
  children,
  headingVariant,
  tituloVariant,
  hiddenBlock,
} from '../animations/animations';

// Styles
import '../style/HomePage.less';

// Images

import { ReactComponent as LuxpitalityLogo } from '../images/SVG/luxpitality_Icon_White.svg';

// Components
import SEO from '../components/SEO';
import NavigationBar from '../components/NavigationBar';
import HomePageHeader from '../components/HomePageHeader';
import CarouselHomePage from '../components/CarouselHomePage';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faHeart,
  faCalculator,
  faSearch,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';

import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';

import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import TemporalHomePageHeader from '../components/TemporalHomePageHeader';

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();
  const controls5 = useAnimation();
  const controls6 = useAnimation();

  const { scrollY } = useViewportScroll();
  // const y1 = useTransform(scrollY, [0, 100], [0, 0]);
  const y1 = useTransform(scrollY, [1800, 0], [0, 1]);
  const y2 = useTransform(scrollY, [800, 0], [0, 1]);
  const y3 = useTransform(scrollY, [3500, 3700], [0, 0]);
  const y4 = useTransform(scrollY, [3500, 3800], [0, -150]);

  const isMobile = useMediaQuery('(max-width: 991px)');

  const clientsCarouselSettings = {
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '0px',
    infinite: false,
    initialSlide: 0,
    swipeToSlide: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 772,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const handleClick = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const Client = Prismic.client(apiEndpoint);

  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at('document.type', 'images')
    );

    return response.results[0].data.body[0].items;
  };

  const fecthWordPress = async () => {
    const response = await axios.get(
      'https://blog.luxpitality.com/wp-json/wp/v2/posts?_embed'
    );
    return response.data;
  };
  const { isLoading, isError, data, error } = useQuery('homepage', fetchData);
  const wp = useQuery('blogData', fecthWordPress);

  return (
    <>
      {isLoading ? (
        <>
          <Spin size='large' tip='Loading...' />
        </>
      ) : (
        <>
          <SEO
            title='Book Boutique Hotels for Individual and Group Travel | Luxpitality'
            description='Luxpitality offers the best boutique hotels and unique resorts. Book individual or group travel online, or become a featured independent hotel partner today.'
            keywords={'boutique hotel'}
          />
          {/* <Button className='modal-btn' type='primary' onClick={handleClick}>
            <LuxpitalityLogo />
          </Button>
          <Modal
            className='popup'
            width='407px'
            visible={showModal}
            onCancel={handleCancel}
            footer={null}
          >
            <img src={data?.[10]?.gallery_image?.url} alt='Welcome' />
            <div className='content'>
              <div className='header'>
                <div className='social'>
                  <a
                    href='https://www.instagram.com/luxpitality'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a
                    href='https://www.facebook.com/luxpitality'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </div>
                <h4>Dreaming of your next escape?</h4>
              </div>
              <div className='body'>
                <p>
                  From the Rocky Mountains to the Northern Lights, chat with us
                  to plan your next adventure! BOOM!
                </p>
                <div>
                  <FontAwesomeIcon icon={faCommentAlt} /> Chat with us
                </div>
                <div>
                  <a
                    target='_blank'
                    href='https://open.spotify.com/playlist/6qyqMC2qjMdwGEy87BsO8w?si=Lc3W1htVSWe4XOG151UMkQ'
                  >
                    <FontAwesomeIcon icon={faVolumeUp} /> See our Spotify
                    Playlist
                  </a>
                </div>
              </div>
            </div>
          </Modal> */}

          <NavigationBar />
          <div className='homeHero'>
            <div>
              <header
                style={{
                  background: `linear-gradient(90deg, rgba(255,255,255,0.4822303921568627) 36%, rgba(255,255,255,0) 100%),url(${data?.[6]?.gallery_image?.url}) `,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top center',
                  backgroundSize: 'cover',
                  height: '80vh',
                  position: 'relative',
                }}
              >
                {/* <TemporalHomePageHeader /> */}
                <HomePageHeader />
              </header>
            </div>
          </div>

          <CarouselHomePage />
          <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls1.start('visible');
              }
            }}
            triggerOnce='true'
          >
            <motion.section className='collections'>
              <div className='collections__heading'>
                <Divider type='vertical' className='separator-vertical' />
                <motion.span variants={headingVariant}>COLLECTIONS</motion.span>
                <motion.h2
                  variants={hiddenBlock}
                  initial='hidden'
                  animate={controls1}
                >
                  <motion.div variants={tituloVariant}>
                    What’s Your Vibe?
                  </motion.div>
                </motion.h2>
              </div>
              <motion.div
                variants={container}
                initial='hidden'
                animate={controls1}
                className='collections__items-container'
              >
                <motion.div
                  variants={children}
                  whileHover='hover'
                  className='item'
                >
                  <span>
                    <img
                      src={data[13].gallery_image.url}
                      alt={data[13].gallery_image.alt}
                    />
                  </span>
                  <span>
                    <Link to='/featured-hotels/country'>Country</Link>
                  </span>
                </motion.div>
                <motion.div
                  variants={children}
                  whileHover='hover'
                  className='item'
                >
                  <span>
                    <img
                      src={data[14].gallery_image.url}
                      alt={data[14].gallery_image.alt}
                    />
                  </span>
                  <span>
                    <Link to='/featured-hotels/coastal'>Coastal</Link>
                  </span>
                </motion.div>
                <motion.div
                  variants={children}
                  whileHover='hover'
                  className='item'
                >
                  <span>
                    <img
                      src={data[8].gallery_image.url}
                      alt={data[8].gallery_image.alt}
                    />
                  </span>
                  <span>
                    <Link to='/featured-hotels/mountain'>Mountain</Link>
                  </span>
                </motion.div>
                <motion.div
                  variants={children}
                  whileHover='hover'
                  className='item'
                >
                  <span>
                    <img
                      src={data[18].gallery_image.url}
                      alt={data[18].gallery_image.alt}
                    />
                  </span>
                  <span>
                    <Link to='/featured-hotels/cityscape'>Cityscape</Link>
                  </span>
                </motion.div>
              </motion.div>
            </motion.section>
          </InView>

          <motion.div style={{ y: y3, x: 0 }}>
            <InView
              as='div'
              onChange={(inView) => {
                if (inView) {
                  controls3.start('visible');
                }
              }}
              triggerOnce='true'
              threshold='0.2'
            >
              <motion.div
                variants={container}
                initial='hidden'
                animate={controls3}
              >
                <Row className='groups-retreats'>
                  <Col sm={24} lg={11} className='groups-retreats__info'>
                    <motion.div variants={hiddenBlock}>
                      <motion.div variants={headingVariant}>
                        <h2>Groups and Retreats</h2>
                      </motion.div>
                    </motion.div>
                    <motion.p variants={headingVariant}>
                      Whether you’re looking to stay local or get off the beaten
                      path, we’ve got the perfect spot for you.
                    </motion.p>
                    <motion.div
                      variants={children}
                      className='links links-block'
                    >
                      <span>
                        <a
                          href='https://groupbookings.luxpitality.com/'
                          rel='noopener noreferrer'
                          target='_blank'
                        >
                          {/* <Link to='/groups'> */}
                          <span>Find Hotels</span>
                          <FontAwesomeIcon icon={faArrowRight} />
                          {/* </Link> */}
                        </a>
                      </span>
                      <span>
                        <Link to='/about'>
                          <span>See our difference</span>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Link>
                      </span>
                      <span>
                        <Link to='/groups'>
                          <span>Request Proposal</span>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Link>
                      </span>
                    </motion.div>
                  </Col>
                  <Col
                    sm={24}
                    lg={11}
                    className='groups-retreats__image-container'
                  >
                    <motion.div
                      style={{ y: isMobile ? 0 : y4, x: 0 }}
                      className='parallaxHome'
                    >
                      <motion.img
                        variants={children}
                        src={data[0].gallery_image.url}
                        alt={data[0].gallery_image.alt}
                      />
                    </motion.div>
                  </Col>
                </Row>
              </motion.div>
            </InView>
          </motion.div>

          <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls4.start('visible');
              }
            }}
            triggerOnce='true'
            threshold='0.2'
          >
            <motion.section
              variants={container}
              initial='hidden'
              animate={controls4}
              className='our-clients'
            >
              <div className='our-clients__intro'>
                <motion.h4 variants={headingVariant}>OUR CLIENTS</motion.h4>
                <motion.div variants={hiddenBlock}>
                  <motion.h2 variants={tituloVariant}>Meet the fam</motion.h2>
                </motion.div>
              </div>
              <motion.div variants={children}>
                <Carousel
                  className='our-clients__carousel-container'
                  {...clientsCarouselSettings}
                >
                  <div className='client-container'>
                    <img
                      src={data[25].gallery_image.url}
                      alt={data[25].gallery_image.alt}
                    />
                  </div>
                  <div className='client-container'>
                    <img
                      src={data[22].gallery_image.url}
                      alt={data[22].gallery_image.alt}
                    />
                  </div>
                  <div className='client-container'>
                    <img
                      src={data[17].gallery_image.url}
                      alt={data[17].gallery_image.alt}
                    />
                  </div>
                  <div className='client-container'>
                    <img
                      src={data[20].gallery_image.url}
                      alt={data[20].gallery_image.alt}
                    />
                  </div>
                </Carousel>
              </motion.div>
            </motion.section>
          </InView>
          <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls5.start('visible');
              }
            }}
            triggerOnce='true'
            threshold='0.3'
          >
            <motion.section
              variants={container}
              initial='visible'
              animate={controls5}
              className='blog-hightlight'
            >
              <div className='separator-vertical-container'>
                <Divider type='vertical' className='separator-vertical' />
              </div>
              <div className='blog-hightlight__blog-posts'>
                {wp.data && (
                  <motion.div
                    variants={children}
                    style={{
                      background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(${wp.data[0]._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url}) `,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'top center',
                      backgroundSize: 'cover',
                      width: '100%',
                    }}
                  >
                    <div className='main-post__info'>
                      <a href={wp.data[0].link} className='blog-title'>
                        {wp.data[0].title.rendered}
                      </a>
                      <a href={wp.data[0].link} className='blog-link'>
                        Read Article <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </div>
                  </motion.div>
                )}
                <Row className='posts'>
                  {wp.data &&
                    wp.data.slice(1, 4).map((item) => (
                      <Col sm={24} lg={7} key={item.id}>
                        <motion.div variants={children}>
                          <div className='img-container'>
                            <img
                              className='blogImg'
                              src={
                                item._embedded['wp:featuredmedia'][0]
                                  .media_details.sizes.medium_large.source_url
                              }
                              alt={
                                item._embedded['wp:featuredmedia'][0].alt_text
                              }
                            />
                          </div>
                          <div className='info'>
                            <h5>
                              <a href={item.link}>{item.title.rendered}</a>
                            </h5>
                          </div>
                        </motion.div>
                      </Col>
                    ))}
                </Row>
              </div>
            </motion.section>
          </InView>
          <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls6.start('visible');
              }
            }}
            triggerOnce='true'
            threshold='0.3'
          >
            <section className='hotel-family'>
              <div
                className='hotel-family-bg'
                style={{
                  background: `url(${data[23].gallery_image.url}) `,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'left',
                  backgroundSize: 'cover',
                  height: '100%',
                  width: '50%',
                }}
              ></div>
              <div className='hotel-container'>
                <div className='hotel-family__header'>
                  <motion.div
                    initial='hidden'
                    animate={controls6}
                    variants={hiddenBlock}
                    className='title'
                  >
                    <motion.span variants={tituloVariant}>
                      Your Trusted <br /> Hotel Gurus
                    </motion.span>
                  </motion.div>
                </div>
                <motion.div
                  variants={container}
                  initial='hidden'
                  animate={controls6}
                  className='hotel-family__content'
                >
                  <div className='info'>
                    <motion.p variants={headingVariant}>
                      At Luxpitality, we focus on our member hotels that radiate
                      design, culture, local roots, and the story that so
                      passionately drove them to be unique. The culmination of
                      these stories creates the unique Luxpitality brand of
                      hotels.
                    </motion.p>
                    <div className='links'>
                      <motion.a
                        variants={headingVariant}
                        href='/about'
                        className='fill-link'
                      >
                        Learn about our mission
                        <FontAwesomeIcon icon={faArrowRight} />
                      </motion.a>
                      <motion.a variants={headingVariant} href='/hoteliers'>
                        Join our hotel family
                      </motion.a>
                    </div>
                  </div>
                </motion.div>
              </div>
            </section>
          </InView>
          <Subscribe />
          <Footer />
        </>
      )}
    </>
  );
};

export default HomePage;
