import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import { motion, useAnimation } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import Prismic from 'prismic-javascript';
import { apiEndpoint } from '../prismic-configuration';
import { accessToken } from '../prismic-configuration';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';

// import { Date, Link, RichText } from 'prismic-reactjs';

// Styles
import '../style/HotelDetails.less';

// Components
import SEO from '../components/SEO';
import NavigationBar from '../components/NavigationBar';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer';

// Animations
import {
  container,
  headingVariant,
  hiddenBlock,
  tituloVariant,
} from '../animations/animations';

// Images
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';

// Map
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import { ScrollZoomHandler } from 'mapbox-gl';
import { Link } from 'react-router-dom';

const Client = Prismic.client(apiEndpoint, { accessToken });

const HotelDetails = ({ match }) => {
  const controls = useAnimation();
  const controls1 = useAnimation();
  const controls2 = useAnimation();

  const hotelId = match.params.id;

  const [doc, setDocData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.getByUID('hotel', hotelId);
      if (!response) {
        setDocData(null);
      }

      const data = await Client.query(
        Prismic.Predicates.at('document.type', 'images')
      );

      if (response && data) {
        setDocData(response);
        setData(data.results[0].data.body[0].items);
      }
    };
    fetchData();
  }, []);

  // Custom Hook
  const { openLightbox } = useLightbox();

  const Map = ReactMapboxGl({
    accessToken:
      'pk.eyJ1IjoidWx5c3Nlc29keXNzZXVzIiwiYSI6ImNrY3h5ZW94bjA0bzczMG8zeTBpbHlhcnoifQ.Ih911PQNK5EZR2fLQx8y_g',
    doubleClickZoom: false,
    scrollZoom: false,
  });

  if (!doc) {
    return <Redirect to='/featured-hotels' />;
  }

  // SEO data
  let metaTitle = null;
  let metaDescription = null;
  let metaKeywords = null;
  if (doc.data) {
    const { hotel_meta, meta_description, meta_keywords } = doc.data;
    if (hotel_meta && hotel_meta.length > 0) {
      metaTitle = hotel_meta[0].text;
    }
    if (meta_description && meta_description.length > 0) {
      metaDescription = meta_description[0].text;
    }
    if (meta_keywords && meta_keywords.length > 0) {
      metaKeywords = meta_keywords[0].text;
    }
  }

  return (
    <>
      {doc.data && data[12] ? (
        <>
          <SEO
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
          />
          <section
            className='hotel-cover'
            style={{
              position: 'relative',
              backgroundImage: `url(${doc.data.hotelphotomain.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'top center',
              minHeight: '85vh',
              backgroundColor: '#0000002b',
              backgroundBlendMode: 'overlay',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <NavigationBar />

            <div className='hotel-cover__info'>
              <>
                <motion.div
                  variants={hiddenBlock}
                  initial='hidden'
                  animate='visible'
                >
                  <motion.h1
                    variants={tituloVariant}
                    className='title title-white'
                  >
                    {doc.data.hotelname[0].text}
                  </motion.h1>
                </motion.div>
                <div className='main-details'>
                  <p>{doc.data.hotellocation[0].text}</p>
                  <p>{doc.data.category}</p>
                </div>
              </>
            </div>

            <div className='reserve-buttons'>
              <button className='reserve-container'>
                {doc.data.guestcentric ? (
                  <a href={doc.data.guestcentric1.url}>
                    <span className='square square--big'></span>
                    Reserve Online <FontAwesomeIcon icon={faArrowRight} />
                  </a>
                ) : doc.data.smith.url ? (
                  <a href={doc.data.smith.url}>
                    <span className='square square--big'></span>
                    Reserve Online <FontAwesomeIcon icon={faArrowRight} />
                  </a>
                ) : null}
              </button>

              {doc?.data?.ivvy_link?.url && (
                <button className='reserve-container-transparent'>
                  <a href={doc?.data?.ivvy_link?.url}>
                    Group Booking
                    <FontAwesomeIcon icon={faArrowRight} />
                  </a>
                </button>
              )}
            </div>
          </section>

          <section className='hotel-details'>
            <div className='hotel-details__text-block'>
              <InView
                as='div'
                onChange={(inView) => {
                  if (inView) {
                    controls.start('visible');
                  }
                }}
                triggerOnce='true'
                threshold='0.3'
              >
                <motion.div className='block-container'>
                  <motion.div
                    variants={container}
                    initial='hidden'
                    animate={controls}
                  >
                    <motion.div variants={hiddenBlock}>
                      <motion.h2 variants={tituloVariant}>
                        Hotel Details
                      </motion.h2>
                    </motion.div>
                    <motion.p variants={headingVariant}>
                      {doc.data.hoteldescription[0].text}
                    </motion.p>
                    <motion.div variants={headingVariant} className='links'>
                      {doc.data.body[0] && (
                        <div className='gallery-link'>
                          <FontAwesomeIcon icon={faImage} />
                          <a onClick={() => openLightbox()}>Photo Gallery</a>
                        </div>
                      )}
                      <div className='categories'>
                        <span>{doc.data.category}</span>
                      </div>
                    </motion.div>
                    <motion.div className='gallery-container'>
                      {doc.data.body[0] && (
                        <SRLWrapper>
                          {doc.data.body[0].items.map((item, key) => {
                            return (
                              <img
                                src={item.gallery_image.url}
                                alt={item.gallery_image.alt}
                                key={key + 1}
                              />
                            );
                          })}
                        </SRLWrapper>
                      )}
                    </motion.div>
                  </motion.div>
                </motion.div>
              </InView>
            </div>
            <div className='hotel-details__cover'>
              <img src={`${doc.data.hoteldetailsphoto.url}`} alt='' />
            </div>
          </section>

          <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls1.start('visible');
              }
            }}
            triggerOnce='true'
            threshold='0.6'
          >
            <motion.section
              variants={container}
              initial='hidden'
              animate={controls1}
              className='experiences'
            >
              <div className='experiences__title'>
                <motion.div variants={hiddenBlock}>
                  <motion.span variants={tituloVariant}>
                    {doc.data.hotelexperiencestitle[0].text}
                  </motion.span>
                </motion.div>
              </div>
              <motion.div
                variants={headingVariant}
                className='experiences__links links-block'
              >
                <span>
                  <a href='# '>
                    <span> {doc.data.hotelexpereinceone[0].text}</span>
                  </a>
                </span>
                <span>
                  <a href='# '>
                    <span>{doc.data.hotelexperiencetwo[0].text}</span>
                  </a>
                </span>
                <span>
                  <a href='# '>
                    <span>{doc.data.hotelexperiencethree[0].text}</span>
                  </a>
                </span>
              </motion.div>
            </motion.section>
          </InView>

          <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls2.start('visible');
              }
            }}
            triggerOnce='true'
            threshold='0.3'
          >
            <section className='ready-to-stay'>
              {doc.data.hotelcoordinates.longitude && (
                <div className='ready-to-stay__map'>
                  <Map
                    style='mapbox://styles/mapbox/streets-v9'
                    center={[
                      doc.data.hotelcoordinates.longitude,
                      doc.data.hotelcoordinates.latitude,
                    ]}
                    zoom={[12]}
                  >
                    <Layer
                      type='symbol'
                      id='marker'
                      layout={{ 'icon-image': 'marker-15' }}
                    >
                      <Feature coordinates={[-117.0550162, 34.1240732]} />
                    </Layer>
                  </Map>
                </div>
              )}
              <motion.div
                variants={container}
                initial='hidden'
                animate={controls2}
                className='ready-to-stay__directions'
              >
                <motion.span variants={headingVariant} className='name'>
                  {doc.data.hotelname[0].text}
                </motion.span>

                <motion.div variants={hiddenBlock}>
                  <motion.h2 variants={tituloVariant}>
                    Area Information
                  </motion.h2>
                </motion.div>

                <motion.p variants={headingVariant}>
                  {doc.data.hotelareadescription[0].text}
                </motion.p>
              </motion.div>

              <div
                style={{
                  background: `url(${data[12].gallery_image.url})  #021825`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center bottom',
                  backgroundSize: 'cover',
                  padding: '10%',
                }}
              >
                <InView
                  as='div'
                  onChange={(inView) => {
                    if (inView) {
                      controls2.start('visible');
                    }
                  }}
                  triggerOnce='true'
                  threshold='0.3'
                  className='ready-to-stay__request-rfp'
                >
                  <div>
                    <motion.div
                      variants={container}
                      initial='hidden'
                      animate={controls2}
                    >
                      <motion.div variants={hiddenBlock}>
                        <motion.span variants={tituloVariant} className='title'>
                          Ready to stay?
                        </motion.span>
                      </motion.div>

                      {doc.data.guestcentric ? (
                        <motion.button variants={headingVariant}>
                          <a href={doc.data.guestcentric1.url}>
                            Book Now <FontAwesomeIcon icon={faArrowRight} />
                          </a>
                        </motion.button>
                      ) : doc.data.smith.url ? (
                        <motion.button variants={headingVariant}>
                          <a href={doc.data.smith.url}>
                            Book Now <FontAwesomeIcon icon={faArrowRight} />
                          </a>
                        </motion.button>
                      ) : null}
                    </motion.div>
                  </div>
                </InView>
              </div>
            </section>
          </InView>
          <Subscribe />
          <Footer />
        </>
      ) : (
        <>
          <Spin size='large' tip='Loading...' />
        </>
      )}
    </>
  );
};

export default HotelDetails;
