import React from "react"

const RedirectBlog = (props) => {
    const blogUrl = "https://blog.luxpitality.com"
    const { slug } = props.match.params
    if (!slug) {
        window.location.href = blogUrl
        return null
    }
    window.location.href = `${blogUrl}/${slug}/`
    return null
}

export default RedirectBlog
