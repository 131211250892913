import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import './style/index.less';
import App from './App';


render(
  <SimpleReactLightbox>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SimpleReactLightbox>,  
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


