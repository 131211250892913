export const carouselVariant = {
  hidden: {
    opacity: 0,
    y: '100%',
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

export const container = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
      // duration: 1,
      when: 'beforeChildren',
      staggerChildren: 0.3,
    },
  },
};

export const children = {
  hidden: {
    opacity: 0,
    y: 150,
  },
  visible: {
    opacity: 1,
    y: 0,

    transition: {
      duration: 1,
    },
  },
  hover: {
    scale: 1.05,
    transition: {},
  },
};

export const hiddenBlock = {
  hidden: {
    backgroundColor: 'transparent',
    height: 'auto',
    zIndex: 1,
    overflow: 'hidden',
  },
  visible: {
    height: '100%',
    transition: {
      staggerChildren: 0.35,
    },
  },
};

export const hiddenBlockServices = {
  hidden: {
    backgroundColor: 'transparent',
    position: 'relative',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
  },
  visible: {
    height: 'auto',
  },
};

export const hiddenBlockAbout = {
  hidden: {
    backgroundColor: 'transparent',
    position: 'relative',
    height: 'auto',
    zIndex: 1,

    overflow: 'hidden',
  },
  visible: {
    height: 'auto',
  },
};

export const hiddenBlockAboutHero = {
  hidden: {
    backgroundColor: 'transparent',
    position: 'relative',
    height: 'auto',
    zIndex: 1,
    overflow: 'hidden',
  },
  visible: {
    height: 'auto',
    width: 'fit-content',
  },
};

export const tituloAbout = {
  hidden: {
    opacity: 1,
    y: 120,
    display: 'block',
  },
  visible: {
    y: 0,

    transition: {
      duration: 1,
    },
  },
};

export const tituloVariant = {
  hidden: {
    opacity: 1,
    y: '100%',
    display: 'block',
  },
  visible: {
    y: 0,
    paddingBottom: 16,

    transition: {
      ease: 'easeIn',
      duration: 1,
    },
  },
};

export const carouselTituloVariant = {
  hidden: {
    opacity: 1,
    y: '100%',
  },
  visible: {
    y: 0,
    paddingBottom: 16,

    transition: {
      ease: 'easeIn',
      duration: 1,
    },
  },
};

export const headingVariant = {
  hidden: {
    opacity: 0,
    y: '100%',
  },
  visible: {
    opacity: 1,
    y: 0,

    transition: {
      duration: 0.8,
    },
  },
};

export const textVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 2,
    },
  },
};

export const hoteliersVariant = {
  hidden: {
    opacity: 0,
    y: 100,
    zIndex: 1,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 2.5,
    },
  },
};

export const hoteliersImgVariant = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      delay: 0.5,
      duration: 1,
    },
  },
};

export const hoteliersContentVariant = {
  hidden: {
    opacity: 0,

    width: '60vw',
    zIndex: 1,
  },
  visible: {
    opacity: 1,

    transition: {
      delay: 0.5,
      duration: 1,
    },
  },
};
