import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { InView } from 'react-intersection-observer';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import {
  container,
  hiddenBlock,
  tituloVariant,
} from '../animations/animations';

const Subscribe = () => {
  const controls = useAnimation();

  return (
    <>
      <InView
        as='div'
        onChange={(inView) => {
          if (inView) {
            controls.start('visible');
          }
        }}
        triggerOnce='true'
        threshold='0.3'
      >
        <section
          variants={container}
          initial='hidden'
          animate={controls}
          className='subscribe'
        >
          <motion.div
            variants={hiddenBlock}
            initial='hidden'
            animate={controls}
          >
            <motion.h2 variants={tituloVariant}>
              Become a Luxlyfe Member
            </motion.h2>
          </motion.div>

          <div className='iframeResponsive'>
            <iframe
              src='https://share.hsforms.com/1ez60LNxPRuebV_sJ24yARg2kq43'
              frameBorder='0'
              width='660px;'
              height='300px'
            ></iframe>
          </div>
        </section>
      </InView>
    </>
  );
};

export default Subscribe;
