import React from 'react';

import { Row, Col, Tabs, Divider, Spin } from 'antd';
import {
  motion,
  useAnimation,
  useTransform,
  useViewportScroll,
} from 'framer-motion';
import { InView } from 'react-intersection-observer';
import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { useQuery } from 'react-query';

// Components
import SEO from '../components/SEO';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import Subscribe from '../components/Subscribe';

// Animations
import {
  container,
  textVariant,
  tituloVariant,
  hiddenBlock,
  hiddenBlockAbout,
  tituloAbout,
  hiddenBlockAboutHero,
} from '../animations/animations';

// Styles
import '../style/App.less';
import '../style/About.less';

// Icons and images
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';

library.add(faSun);

const About = () => {
  const { TabPane } = Tabs;
  const controls = useAnimation();
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();

  const { scrollY } = useViewportScroll();

  const y1 = useTransform(scrollY, [0, 300], [0, 0]);
  const y2 = useTransform(scrollY, [0, 300], [0, -130]);

  const Client = Prismic.client(apiEndpoint);

  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at('document.type', 'images')
    );

    return response.results[0].data.body[0].items;
  };

  const { isLoading, data } = useQuery('about', fetchData);

  return (
    <>
      {isLoading ? (
        <>
          <Spin size='large' tip='Loading...' />
        </>
      ) : (
        <>
          <SEO
            title='About Us, Our Independent Hotels, Partners and Services | Luxpitality'
            description='Learn why Luxpitality is the leading independent hotel marketing and sales company. About us, our culture, mission and our unique hotel partners and services.'
            keywords={'independent hotels'}
          />
          <header id='About'>
            <NavigationBar />
          </header>
          <motion.div style={{ y: y2, x: 0 }}>
            <div
              className='main-image'
              style={{
                background: `url(${data[1].gallery_image.url})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center',
                backgroundSize: 'cover',
                position: 'relative',
                minHeight: '80vh',
              }}
            >
              <motion.div style={{ y: y1, x: 0 }}>
                <div className='header-title'>
                  <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 20, offset: 2 }}
                    md={{ span: 20, offset: 2 }}
                    lg={{ span: 10, offset: 2 }}
                    xl={{ span: 10, offset: 2 }}
                  >
                    <motion.div
                      variants={hiddenBlockAboutHero}
                      initial='hidden'
                      animate='visible'
                    >
                      <motion.h1 variants={tituloVariant}>
                        Creativity.Efficiency.
                        <br></br>
                        Honesty.Sustainability.
                      </motion.h1>
                    </motion.div>
                  </Col>
                </div>
              </motion.div>
            </div>
          </motion.div>
          <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls.start('visible');
              }
            }}
            triggerOnce='true'
            threshold='0.2'
          >
            <motion.div
              variants={container}
              initial='hidden'
              animate={controls}
              className='who-we-are'
            >
              <Row>
                <Col
                  className='align-left'
                  xs={{ span: 20, offset: 2 }}
                  sm={{ span: 20, offset: 2 }}
                  md={{ span: 20, offset: 2 }}
                  lg={{ span: 10, offset: 2 }}
                  xl={{ span: 10, offset: 2 }}
                >
                  <motion.div variants={hiddenBlockAbout}>
                    <motion.span variants={tituloAbout}>
                      This is who we are.
                      <br /> This is what we do.
                    </motion.span>
                  </motion.div>
                  <Col className='align-below' span={24}>
                    <motion.div variants={textVariant}>
                      <p>Find out about who we are</p>
                    </motion.div>
                  </Col>
                  <div
                    className='aboutSeparator'
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'initial',
                    }}
                  >
                    <div
                      className='separator-w-square-about'
                      style={{ marginLeft: '5rem', marginTop: '2rem' }}
                    >
                      <Divider
                        type='vertical'
                        className='separator-vertical-about-hero'
                      />
                      <div className='squareAbout'></div>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={{ span: 20, offset: 2 }}
                  sm={{ span: 20, offset: 2 }}
                  md={{ span: 20, offset: 2 }}
                  lg={{ span: 10, offset: 1 }}
                  xl={{ span: 10, offset: 0 }}
                  className='align-right'
                >
                  <motion.div variants={textVariant}>
                    <span>
                      Luxpitality is a collection of unique hotels and retreats
                      that radiate design, culture, local roots, and the story
                      that so passionately drove them to be unique. The
                      culmination of these stories creates the unique
                      Luxpitality brand of hotels. These hotels exude an
                      entrepreneurial drive to be different forward-thinking,
                      and progressive trailblazers that shape the future of
                      hospitality.
                      <br />
                      <br />
                      Luxpitality also has an extensive database of travelers
                      and meeting and event planners and Luxpitality provides
                      these trailblazers a way to experience the coolest
                      collection of unique boutique hotels.
                    </span>
                  </motion.div>
                </Col>
              </Row>
            </motion.div>
          </InView>
          <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls1.start('visible');
              }
            }}
            triggerOnce='true'
            threshold='0.3'
          >
            <motion.div
              variants={container}
              initial='hidden'
              animate={controls1}
              className='our-mission'
            >
              <Col
                xs={{ span: 20, offset: 2 }}
                sm={{ span: 20, offset: 2 }}
                md={{ span: 20, offset: 2 }}
                lg={{ span: 12, offset: 2 }}
                xl={{ span: 12, offset: 2 }}
              >
                <div className='container'>
                  <Row>
                    <motion.div variants={textVariant} className='travelers'>
                      <span>OUR MISSION</span>
                    </motion.div>
                  </Row>
                  <Row>
                    <motion.div
                      variants={hiddenBlock}
                      className='hotel-portfolio'
                    >
                      <motion.span variants={tituloVariant}>
                        Our hotel portfolio radiates local <br /> culture and
                        lifestyle for our discerning <br />
                        travelers.
                      </motion.span>
                    </motion.div>
                  </Row>
                  <Row>
                    <motion.div variants={textVariant} className='shoebottom'>
                      <p>
                        —the new-age nomad, trailblazing entrepreneurs, wellness
                        enthusiasts, and wanderlusters. From weekend getaways to
                        executive retreats, we’ll take you to locally-rooted,
                        thought-provoking destinations while offsetting your
                        carbon footprint the entire way. No matter where you go,
                        we will leave you craving your next crafted journey.
                      </p>
                    </motion.div>
                  </Row>
                  <Row>
                    <motion.div
                      variants={textVariant}
                      className='travelers'
                      style={{ marginBottom: '36px' }}
                    >
                      <span>OUR VISION</span>
                    </motion.div>
                  </Row>

                  <Row>
                    <motion.div variants={textVariant} className='shoebottom'>
                      <p>
                        Our vision is to continue fostering a community of hotel
                        partners comprised of the coolest & most unique
                        properties around the globe and to continue matching
                        them with the coolest & most innovative planners.
                      </p>
                    </motion.div>
                  </Row>
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    width: '80vw',
                    height: '90vh',
                    maxWidth: '600px',
                    top: '-150px',
                    right: '0',
                    position: 'absolute',
                    background: `url(${data[2].gallery_image.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top center',
                  }}
                ></div>
              </Col>
            </motion.div>
          </InView>
          {/* <InView
              as='div'
              onChange={(inView) => {
                if (inView) {
                  controls2.start('visible');
                }
              }}
              triggerOnce='true'
              threshold='0.3'
            >
              <motion.div
                variants={container}
                initial='hidden'
                animate={controls2}
                className='kids'
              >
                <Row>
                  <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 20, offset: 2 }}
                    md={{ span: 20, offset: 2 }}
                    lg={{ span: 8, offset: 2 }}
                    xl={{ span: 8, offset: 2 }}
                  >
                    <motion.div
                      variants={textVariant}
                      className='video-responsive'
                    >
                      <iframe
                        title='myclimate-solutions'
                        width='560'
                        height='315'
                        src='https://www.youtube.com/embed/lWnJfdD6_4k'
                        frameBorder='0'
                        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                      ></iframe>
                    </motion.div>
                  </Col>
                  <Col
                    className='meet-our-partners'
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 20, offset: 2 }}
                    md={{ span: 20, offset: 2 }}
                    lg={{ span: 10, offset: 2 }}
                    xl={{ span: 10, offset: 2 }}
                    xxl={{ span: 8, offset: 3 }}
                  >
                    <motion.div variants={textVariant}>
                      <FontAwesomeIcon
                        icon={faSun}
                        style={{
                          color: 'red',
                          padding: '0',
                          width: '30',
                        }}
                      />
                      <span className='kids-title'>
                        Meet Our Sustainability Partners
                    </span>
                      <p className='kids-content'>
                        Luxpitality strives for social responsibility and
                        sustainable travel. In partnership with myclimate, we work
                        with every traveler and every hotel member by funding
                        special projects to reduce the carbon footprint and ensure
                        the long term health of our planet.
                    </p>
                      <div>
                        <img
                          src={data[24].gallery_image.url}
                          alt={data[24].gallery_image.alt}
                          width='150'
                        />
                        <Link to='/csr' className='kids-button'>
                          Let's take a stand
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                        </Link>
                      </div>
                    </motion.div>
                  </Col>
                </Row>
              </motion.div>
            </InView> */}
          <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls3.start('visible');
              }
            }}
            triggerOnce='true'
            threshold='0.3'
          >
            <div className='aboutSeparator' style={{ position: 'relative' }}>
              <div
                className='separator-w-square-about'
                style={{ marginBottom: '-9rem' }}
              >
                <Divider type='vertical' className='separator-vertical-about' />
              </div>
            </div>
            <motion.div
              variants={container}
              initial='hidden'
              animate={controls3}
              style={{
                marginTtop: '4rem',
                background: `url(${data[3].gallery_image.url})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center bottom',
                backgroundSize: 'cover',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
              className='marketing-image'
            >
              <Row style={{ width: '100%' }}>
                <Col
                  xs={{ span: 20, offset: 2 }}
                  sm={{ span: 20, offset: 2 }}
                  md={{ span: 20, offset: 2 }}
                  lg={{ span: 14, offset: 2 }}
                  xl={{ span: 12, offset: 2 }}
                >
                  <div className='bgImage'>
                    <div className='box'>
                      <motion.div
                        variants={textVariant}
                        className='join_our_movement'
                      >
                        <span>Team Up With Us</span>
                      </motion.div>
                      <motion.div variants={hiddenBlock}>
                        <motion.span
                          variants={tituloAbout}
                          className='join_market_move'
                        >
                          Join the <br /> Movement
                        </motion.span>
                      </motion.div>
                      <motion.div variants={textVariant}>
                        <Tabs defaultActiveKey='1' className='tabs'>
                          <TabPane tab='Marketers' key='1'>
                            <motion.div className='body_copy'>
                              <p>
                                <br />
                                Looking to make a splash in the industry that
                                let’s you travel the world and be a true
                                representation of yourself? We are too and we
                                are always looking for the most creative wanders
                                to help amplify the movement!
                                <br /> <br /> SEO <br />
                                Affiliate Marketing <br /> Social Media <br />
                                Content Creation <br /> Photography <br />
                                Videography <br /> Influencer Trips <br />
                                <br />
                              </p>
                            </motion.div>
                            <motion.div className='diamond'>
                              <a href='mailto:info@luxpitality.com'>
                                JOIN OUR HOTEL FAM
                                <FontAwesomeIcon icon={faLongArrowAltRight} />
                              </a>
                            </motion.div>
                          </TabPane>
                          <TabPane tab='Sales' key='2'>
                            <motion.div className='body_copy'>
                              <p>
                                <br />
                                Your self-starting attitude, sales experience,
                                and industry expertise have a home at
                                Luxpitality. If you love the hospitality
                                industry, but think it could use a little
                                shakeup, we want to hear from you. Our diverse
                                group of global sales talent thrives a craft
                                experience for our clients and is built on the
                                backbone of our core values:
                                <br /> <br /> Creativity <br />
                                Efficiency <br /> Honesty <br />
                                Sustainability <br />
                                <br />
                              </p>
                            </motion.div>
                            <motion.div className='diamond'>
                              <a href='mailto:info@luxpitality.com'>
                                JOIN OUR HOTEL FAM
                                <FontAwesomeIcon icon={faLongArrowAltRight} />
                              </a>
                            </motion.div>
                          </TabPane>
                        </Tabs>
                      </motion.div>
                    </div>
                  </div>
                </Col>
              </Row>
            </motion.div>
            <div className='aboutSeparator' style={{ marginTop: -46 }}>
              <div className='separator-w-square-about'>
                <Divider type='vertical' className='separator-vertical-about' />
              </div>
            </div>
          </InView>
          {/* <InView
            as='div'
            onChange={(inView) => {
              if (inView) {
                controls4.start('visible');
              }
            }}
            triggerOnce='true'
            threshold='0.3'
          >
            <motion.div
              variants={container}
              initial='hidden'
              animate={controls4}
              className='follow_lux'
            >
              <Row>
                <motion.div variants={hiddenBlock}>
                  <motion.span variants={tituloVariant} className='follow_us'>
                    Follow Us
                  </motion.span>
                </motion.div>
              </Row>
              <Row>
                <motion.span variants={textVariant} className='at_lux'>
                  <a
                    href='https://www.instagram.com/luxpitality/'
                    target='_blank'
                  >
                    @LUXPITALITY
                  </a>
                </motion.span>
              </Row>
              <motion.div variants={textVariant}>
                <div className='grid-images'>
                  <iframe
                    src='https://www.powr.io/social-feed/u/798d2e21_1597803356#platform=iframe'
                    style={{ width: '100%' }}
                    height='600px'
                    frameBorder='0'
                  ></iframe>
                </div>
              </motion.div>
            </motion.div>
          </InView> */}
          <Subscribe />
          <Footer />
        </>
      )}
    </>
  );
};

export default About;
