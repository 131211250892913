import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { motion } from 'framer-motion';

// Styles
import '../style/HotelsList.less';

import {
  children,
  headingVariant,
  tituloVariant,
  hiddenBlockServices,
} from '../animations/animations';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

const HotelCard = ({ hotel }) => {
  return (
    <motion.section
      className='result-info__hotels'
      key={hotel?.id || hotel?.hotels?._meta?.id}
    >
      <motion.div
        variants={children}
        initial='hidden'
        animate='visible'
        className='hotel'
      >
        <motion.div className='image'>
          <ReactLink
            to={
              hotel?.hotels?._meta?.uid
                ? { pathname: `/featured-hotel/${hotel?.hotels?._meta?.uid}` }
                : { pathname: `/featured-hotel/${hotel.slugs[0]}` }
            }
          >
            <img
              src={
                hotel?.data?.hotelphotomain?.url ||
                hotel.hotels.hotelphotomain.url
              }
              alt='hotel result'
            />
          </ReactLink>
        </motion.div>
        <motion.div className='info'>
          <motion.div variants={hiddenBlockServices} className='header'>
            <ReactLink
              to={
                hotel?.hotels?._meta?.uid
                  ? { pathname: `/featured-hotel/${hotel?.hotels?._meta?.uid}` }
                  : { pathname: `/featured-hotel/${hotel.slugs[0]}` }
              }
              className='details'
            >
              <motion.h6 variants={tituloVariant}>
                {hotel?.data?.hotelname[0]?.text ||
                  hotel?.hotels?.hotelname[0]?.text}
              </motion.h6>
            </ReactLink>
            <span className='location'>
              {hotel?.data?.hotellocation[0]?.text ||
                hotel?.hotels?.hotellocation[0]?.text}
            </span>
          </motion.div>
          <motion.div variants={headingVariant} className='body'>
            {hotel?.data?.hoteldescription[0]?.text ||
              hotel?.hotels?.hoteldescription[0]?.text}
          </motion.div>
          <motion.div variants={headingVariant} className='bottom'>
            <div className='categories'>
              <span className='category'>
                {hotel?.data?.category || hotel?.hotels.category}
              </span>
            </div>
            <div className='buttons'>
              <ReactLink
                to={
                  hotel?.hotels?._meta?.uid
                    ? {
                        pathname: `/featured-hotel/${hotel?.hotels?._meta?.uid}`,
                      }
                    : { pathname: `/featured-hotel/${hotel.slugs[0]}` }
                }
                className='details'
              >
                <span>Details</span>
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </ReactLink>
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

export default HotelCard;
