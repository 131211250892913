import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"


const SEO = (props) => {
    const { title, description, keywords } = props

    return (
        <Helmet>
            { props.title && (
                <title>{ title }</title> 
            )}
            
            { props.description && (
                <meta name="description" content={ description } />
            )}

            { props.keywords && props.keywords.length > 0 && (
                <meta name="keywords" content={ keywords } />
            )}
        </Helmet>
    )
}

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
}

export default SEO
