import React from 'react';
import { Redirect } from 'react-router-dom';

// Map of renamed hotels { old : new }
const renamedHotels = {
  'ace-palm-springs': 'ace',
  'carneros-resort-spa': 'carneros-resort-and-spa',
  'parker-palm-springs': 'parker',
  'stein-eriksen-lodge-deer-valley': 'stein-eriksen-lodge',
};

const RedirectFeaturedHotels = (props) => {
  const { id } = props.match.params;
  const newId = renamedHotels[id];
  console.log(renamedHotels);
  console.log(renamedHotels['hola']);
  if (newId) {
    return <Redirect to={`/featured-hotel/${newId}/`} />;
  }
  return <Redirect to={`/featured-hotel/${id}/`} />;
};

export default RedirectFeaturedHotels;
