import React from 'react';
import { DatePicker } from 'antd';
import { motion } from 'framer-motion';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapPin,
  faSearch,
  faArrowRight,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';

// Animations
import { tituloVariant, hiddenBlock } from '../animations/animations';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import SearchTabs from './SearchTabs';

// Date Component
const { RangePicker } = DatePicker;

const HomePageHeader = () => {
  const [dayIn, setDayIn] = useState('');
  const [dayOut, setDayOut] = useState('');
  const [yearMonthIn, setYearMonthIn] = useState('');
  const [yearMonthOut, setYearMonthOut] = useState('');
  const [location, setLocation] = useState('');

  const dateFormat = 'YYYY-MM-DD';

  const luxBooking = `https://book.luxpitality.com/search.v01.php?s=&d=${location}&c=&v=&cid=${dayIn}&cim=${yearMonthIn}&cod=${dayOut}&com=${yearMonthOut}&nra=2&nrc=0&amount=1&b=1&orderby=price&order=desc`;

  const onChangeHandlerInput = (event) => {
    event.preventDefault();
    setLocation(event.target.value);
  };

  const onClickHandler = () => {
    if (true) {
      window.location.href = `${luxBooking}`;
    } else {
      console.log('You cant search that');
    }
  };

  return (
    <>
      <motion.section className='landing-top' style={{ overflow: 'hidden' }}>
        <motion.div
          variants={hiddenBlock}
          initial='hidden'
          animate='visible'
          className='landing-top__header'
        >
          <motion.h1 variants={tituloVariant}>
            Curated Boutique Lifestyle Hotels
          </motion.h1>
          <motion.span variants={tituloVariant}>
            Getaways, Groups, Meetings and Events
          </motion.span>
        </motion.div>
        {/* <div className="landing-top__booking">
          <div className="booking-options">
            <button className="booking-options--active">
              Individual Booking
            </button>
            <button className="booking-options--inactive">
              <a href="https://groups.luxpitality.com/">Groups &amp; Events</a>
            </button>
            <span className="text">
              Escape to the most unique brand of hotels and retreats
            </span>
          </div>
          <div className="booking-search">
            <RangePicker
              className="booking-search__dates"
              dropdownClassName="booking-search-drop-down"
              placeholder={['Check-in', 'Check-out']}
              separator={<FontAwesomeIcon icon={faArrowRight} />}
              format={dateFormat}
              onChange={(dates, dateStrings) => {
                setDayIn(dateStrings[0].slice(8, 10));
                setDayOut(dateStrings[1].slice(8, 10));
                setYearMonthIn(dateStrings[0].slice(0, 7));
                setYearMonthOut(dateStrings[1].slice(0, 7));
              }}
            />
            <div className="booking-search__location">
              <FontAwesomeIcon icon={faMapPin} />
              <input
                type="text"
                placeholder="Where are we headed?"
                onChange={onChangeHandlerInput}
              />
            </div>
            <button className="booking-search__button" onClick={onClickHandler}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div> */}
        <SearchTabs />
        <div className='ideasLink'>
          <Link to='/featured-hotels'>
            Need ideas? We got you!
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </motion.section>
    </>
  );
};

export default HomePageHeader;
