import React from 'react';
import { useState, useEffect } from 'react';
import { DatePicker, Tabs, Select } from 'antd';
import '../style/Groups.less';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useQuery } from 'react-query';
import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { useHistory } from 'react-router-dom';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapPin,
  faSearch,
  faArrowRight,
  faArrowUp,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';

const PlacesAutocomplete = ({
  setEventCity,
  setEventState,
  setEventCountry,
  setEventCitySplit,
}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      // componentRestrictions:{}
      types: ['(cities)'],
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = (suggestions) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(suggestions.description, false);
    clearSuggestions();

    const parameter = {
      // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
      placeId: suggestions.place_id,
      // Specify the return data that you want (optional)
      fields: ['address_components', 'formatted_address'],
    };

    getDetails(parameter)
      .then((details) => {
        const handleCity = details.address_components.find((x) =>
          x.types.includes('locality')
        );
        setEventCity(handleCity.short_name);

        const handletState = details.address_components.find((x) =>
          x.types.includes('administrative_area_level_1')
        );
        setEventState(handletState.short_name);

        const handleCountry = details.address_components.find((x) =>
          x.types.includes('country')
        );
        setEventCountry(handleCountry.short_name);

        const splitCity = handleCity.short_name
          .replace(/\s/g, '-')
          .toLowerCase();
        setEventCitySplit(splitCity);
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          className='input-searchbar'
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <input
        value={value}
        onChange={handleInput}
        disabled={!ready}
        placeholder='Location'
        className='input-autocomplete'
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && (
        <ul
          style={{ position: 'absolute', zIndex: 999, backgroundColor: '#fff' }}
        >
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

const SearchTabs = () => {
  const { Option } = Select;
  const { TabPane } = Tabs;
  let history = useHistory();

  //States for the luxpitatity events groups search service

  const [eventCity, setEventCity] = useState(null);
  const [eventState, setEventState] = useState(null);
  const [eventCountry, setEventCountry] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [eventCitySplit, setEventCitySplit] = useState(null);

  // Dates for the luxpitatity events groups search service
  const [startDay, setStartDay] = useState(null);
  const [startMonth, setStartMonth] = useState(null);
  const [startYear, setStartYear] = useState(null);

  // States for the luxpitality hotel booking service
  const [location, setLocation] = useState('');

  const dateFormat = 'YYYY-MM-DD';

  const eventBooking = `https://groupbookings.luxpitality.com/${eventCitySplit}--${eventState}.html?startDate=${startMonth}%2F${startDay}%2F${startYear}&radius=100`;
  const eventBooking2 = `https://groupbookings.luxpitality.com/${eventCitySplit}--${eventState}/${eventType}.html?startDate=${startMonth}%2F${startDay}%2F${startYear}&radius=100`;
  const eventBooking3 = `https://groupbookings.luxpitality.com/${eventCitySplit}--${eventCountry}.html?startDate=${startMonth}%2F${startDay}%2F${startYear}&radius=100`;
  const eventBooking4 = `https://groupbookings.luxpitality.com/${eventCitySplit}--${eventCountry}/${eventType}.html?startDate=${startMonth}%2F${startDay}%2F${startYear}&radius=100`;

  const Client = Prismic.client(apiEndpoint);
  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at('document.type', 'location'),
      {
        pageSize: 100,
        orderings: '[document.first_publication_date desc]',
      }
    );
    return response.results;
  };

  const { isLoading, isError, data, error } = useQuery(
    'hotelSelector',
    fetchData
  );

  const onClickHandler = () => {
    history.push(`/featured-hotels/location/${location}`);
  };

  const onEventGroupClick = () => {
    if (eventCountry === 'US' && eventType === null) {
      window.location.href = `${eventBooking}`;
    } else if (eventCountry === 'US' && eventType !== null) {
      window.location.href = `${eventBooking2}`;
    } else if (
      eventCitySplit !== null &&
      eventCountry !== null &&
      eventType === null
    ) {
      window.location.href = `${eventBooking3}`;
    } else if (
      eventType !== null &&
      eventCitySplit !== null &&
      eventCountry !== null
    ) {
      window.location.href = `${eventBooking4}`;
    } else {
      window.location.href = `https://groupbookings.luxpitality.com/`;
    }
  };
  return (
    <div className='landing-top__booking'>
      <Tabs defaultActiveKey='2'>
        <TabPane tab='Book a Getaway' key='1'>
          <div className='booking-search-location'>
            <div className='booking-search-location-box'>
              <FontAwesomeIcon icon={faMapPin} />

              {!isLoading && (
                <Select
                  placeholder='Where are we headed?'
                  onChange={(event) => setLocation(event)}
                >
                  {data?.map((x) => {
                    return (
                      <Option key={x?.id} value={x?.slugs[0]}>
                        {x?.data?.title[0]?.text}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </div>
            <button className='booking-search__button' onClick={onClickHandler}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </TabPane>
        <TabPane tab='Book an Event' key='2'>
          <div className='booking-search'>
            {/* <div className='groups-selector'>
              <FontAwesomeIcon icon={faBullhorn} />
              <Select
                // className='groups-selector-event'
                placeholder='Event Type'
                onChange={(e) => setEventType(e)}
              >
                <Option value='conference'>Corporate Retreat</Option>
                <Option value='corporate'>Wellness Retreat</Option>
                <Option value='event'>Incentive Trip</Option>
                <Option value='meeting'>Board Meeting</Option>
                <Option value='meeting2'>Corporate Meeting</Option>
                <Option value='party'>Social Event</Option>
                <Option value='other'>Other</Option>
              </Select>
            </div> */}
            <div className='booking-search__location groups-location'>
              <FontAwesomeIcon icon={faMapPin} />

              <PlacesAutocomplete
                setEventCity={setEventCity}
                setEventState={setEventState}
                setEventCountry={setEventCountry}
                setEventCitySplit={setEventCitySplit}
              />
            </div>
            <DatePicker
              className='booking-search__dates groups-dates'
              dropdownClassName='booking-search-drop-down'
              placeholder={['Start Date']}
              separator={<FontAwesomeIcon icon={faArrowRight} />}
              format={dateFormat}
              onChange={(date, dateStrings) => {
                setStartDay(dateStrings.slice(8, 10));
                setStartMonth(dateStrings.slice(5, 7));
                setStartYear(dateStrings.slice(0, 4));
              }}
            />
            <button
              className='booking-search__button groups-button'
              onClick={onEventGroupClick}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SearchTabs;
