import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import '../style/Footer.less';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
  faInstagram,
  faTwitter,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';

// Images & SVG
import LuxpitalityLogo from '../images/luxpitality-white.png';

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer>
          <section className='main'>
            <section className='brand-area'>
              <div className='brand-area__logo'>
                <img src={LuxpitalityLogo} alt='Logo' />
              </div>
            </section>
            <section className='contact-site-map'>
              <div>
                <span>(480)-630-1003</span>
                <span>info@luxpitality.com</span>
                <span>10715 N. Frank Lloyd Wright Blvd. #105</span>
                <span>Scottsdale, AZ 85259</span>
              </div>
              <div>
                <Link to='/featured-hotels'>Book a Getaway</Link>
                <a
                  href='https://groupbookings.luxpitality.com'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Book an Event
                </a>
                <Link to='/groups'>For Meeting & Event Planners</Link>
                <Link to='/hoteliers'>For Hoteliers</Link>
              </div>
              <div>
                <a
                  href='http://blog.luxpitality.com/'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Luxlyfe Blog
                </a>
                <Link to='/about'>About Us</Link>

                <Link to='/contact'>Contact Us</Link>
              </div>
            </section>
          </section>
          <section className='bottom'>
            <span className='bottom__text'>
              &copy; Luxpitality {new Date().getFullYear()}.
            </span>
            <span className='bottom__social-links'>
              <a
                href='https://www.instagram.com/luxpitality'
                rel='noopener noreferrer'
                target='_blank'
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href='https://twitter.com/luxpitality'
                rel='noopener noreferrer'
                target='_blank'
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                href='https://www.facebook.com/luxpitality'
                rel='noopener noreferrer'
                target='_blank'
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </span>
          </section>
        </footer>
      </>
    );
  }
}

export default Footer;
