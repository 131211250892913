import React, { useRef, useState } from 'react';
import { Carousel } from 'antd';
import { motion, useAnimation } from 'framer-motion';
import { InView } from 'react-intersection-observer';
import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { useQuery } from 'react-query';
import { BsArrowRight } from 'react-icons/bs';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Animations
import {
  container,
  hiddenBlock,
  carouselTituloVariant,
} from '../animations/animations';
import { Link } from 'react-router-dom';

const CarouselHomePage = () => {
  const [slide, setSlide] = useState(0);
  const slider = useRef();

  const controls = useAnimation();
  const controls1 = useAnimation();

  const carouselSettings = {
    dots: false,
    slidesToShow: 1,
    swipeToSlide: true,
    variableWidth: true,
    easing: 'linear',
    focusOnSelect: true,
    draggable: true,
  };

  const Client = Prismic.client(apiEndpoint);

  const fetchData = async () => {
    const response = await Client.query(
      Prismic.Predicates.at('my.hotel.latestdesign', true),
      { orderings: '[document.first_publication_date desc]' }
    );

    return response.results;
  };

  const { isLoading, data } = useQuery('carousel', fetchData);

  return (
    <>
      <section className='latest-carousel'>
        <InView
          as='div'
          onChange={(inView) => {
            if (inView) {
              controls.start('visible');
            }
          }}
          triggerOnce='true'
          threshold='0.7'
        >
          <motion.div
            variants={hiddenBlock}
            initial='hidden'
            animate={controls}
          >
            <motion.div
              variants={carouselTituloVariant}
              className='latest__title-handlers'
            >
              <span>Our Favorite Getaways</span>
              <span>
                <button
                  className='left-handle'
                  onClick={(e) => {
                    setSlide(e);
                    slider.current.prev(e);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  className='right-handle'
                  onClick={(e) => {
                    setSlide(e);
                    slider.current.next(e);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </span>
            </motion.div>
          </motion.div>
        </InView>

        <InView
          as='div'
          onChange={(inView) => {
            if (inView) {
              controls1.start('visible');
            }
          }}
          triggerOnce='true'
          threshold='0.25'
        >
          <motion.div variants={container} initial='hidden' animate={controls1}>
            <Carousel
              className='latest__carousel'
              ref={(ref) => (slider.current = ref)}
              {...carouselSettings}
            >
              {isLoading ? (
                <> </>
              ) : (
                data.map((hotel) => (
                  <div key={hotel.id}>
                    <div
                      className='carousel-bg'
                      style={{
                        backgroundImage: `url(${hotel.data.hotelphotomain.url})`,
                      }}
                    ></div>
                    {/* <img src={hotel.data.hotelphotomain.url} alt='hotel 4' /> */}
                    <div className='hotel-info'>
                      <span>
                        <p>{hotel.data.hotelname[0].text}</p>
                        <p>{hotel.data.hotellocation[0].text}</p>
                      </span>
                      <Link
                        to={{ pathname: `/featured-hotel/${hotel.slugs[0]}` }}
                      >
                        <BsArrowRight size='30px' />
                        {/* <FontAwesomeIcon icon={faArrowRight} /> */}
                      </Link>
                    </div>
                  </div>
                ))
              )}
            </Carousel>
          </motion.div>
        </InView>
      </section>
    </>
  );
};

export default CarouselHomePage;
