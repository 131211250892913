import React from 'react';
import '../style/contact.less';
import { Row, Col, Tabs } from 'antd';
import NavigationBar from '../components/NavigationBar';
import '../style/App.less';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

//Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faPhone,
  faCommentAlt,
} from '@fortawesome/free-solid-svg-icons';

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
const gridStyle = {
  width: '50%',
  textAlign: 'left',
};
class contact extends React.Component {
  render() {
    return (
      <>
        <SEO
          title='Contact Us for Luxury Hospitality Marketing and Sales | Luxpitality'
          description='Contact Luxpitality to become a hotel partner and discuss luxury hospitality sales and marketing services. Reservation questions? Chat, call or email today!'
          keywords={'hospitality marketing, hospitality sales'}
        />
        <Row>
          <div>
            <header>
              <NavigationBar />
            </header>
          </div>
        </Row>
        <Row className='lets-chat'>
          <Col sm={8} lg={12}>
            <h1 className='headline'>Let’s Chat.</h1>
            <h1 className='headline neggymarge'>Let's Go. </h1>
          </Col>
          <Col sm={18} lg={8} className='lets-chat__info'>
            <div className='link-contact'>
              <h5>General Contact</h5>
              <span>
                <a href='mailto:info@luxpitality.com'>
                  <FontAwesomeIcon
                    style={{ marginRight: 20, paddingLeft: 8 }}
                    icon={faEnvelope}
                  />
                  <span>Email Us</span>
                </a>
              </span>
              <span>
                <a href='# '>
                  <FontAwesomeIcon
                    style={{ marginRight: 20, paddingLeft: 8 }}
                    icon={faPhone}
                  />
                  <span>Call Us (480)-630-1003</span>
                </a>
              </span>
            </div>
          </Col>
        </Row>
        <Footer />
      </>
    );
  }
}
export default contact;
