import React, { useState, useEffect } from 'react';
import { Pagination, Spin } from 'antd';
import { apiEndpoint } from '../prismic-configuration';
import Prismic from 'prismic-javascript';
import { usePaginatedQuery, useQueryClient, useQuery } from 'react-query';
import { motion } from 'framer-motion';
import { PrismicLink } from 'apollo-link-prismic';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';

// Styles
import '../style/HotelsList.less';

import {
  container,
  children,
  headingVariant,
  tituloVariant,
  hiddenBlock,
  hiddenBlockServices,
} from '../animations/animations';

// Components
import SEO from '../components/SEO';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

import HotelCard from '../components/HotelCard';

const HotelList = ({ match }) => {
  const [page, setPage] = useState(1);
  const [categoryData, setCategoryData] = useState([]);

  const Client = Prismic.client(apiEndpoint);
  const categoryId = match.params.categoryId;

  const client = new ApolloClient({
    link: PrismicLink({
      uri: 'https://luxpitality.prismic.io/graphql',
    }),
    cache: new InMemoryCache(),
  });

  const fetchData = async (categoryId) => {
    const categoryData = await client.query({
      query: gql`
        {
          allCategorys {
            edges {
              node {
                name
                background
                _meta {
                  id
                  uid
                }
              }
            }
          }
        }
      `,
    });

    if (categoryId) {
      const response = await Client.query(
        Prismic.Predicates.at('my.hotel.category', categoryId),
        {
          pageSize: 10,
          page: `${page}`,
          orderings: '[document.first_publication_date desc]',
        }
      );
      return { response, categoryData: categoryData.data.allCategorys.edges };
    } else if (match.params.location) {
      const response = await client.query({
        query: gql`
          {
            allLocations(uid: "${match.params.location}") {
              edges {
                node {
                  title
                  hotelLocations {
                    ... on LocationHotelLocations {
                      hotels {
                        ... on Hotel {
                          hotelname
                          hotellocation
                          hoteldescription
                          hotelphotomain
                          category

                          _meta {
                            id
                            uid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      });

      return {
        response: response.data.allLocations.edges[0].node.hotelLocations,
        categoryData: categoryData.data.allCategorys.edges,
      };
    } else {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', 'hotel'),
        {
          pageSize: 10,
          page: `${page}`,
          orderings: '[document.first_publication_date desc]',
        }
      );
      return { response, categoryData: categoryData.data.allCategorys.edges };
    }
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      if (categoryId) {
        const res = await Client.getByUID('category', categoryId);
        if (!res) {
          setCategoryData(null);
        } else {
          setCategoryData(res.data);
        }
      }
    };
    fetchCategoryData();
    setPage(1);
  }, [categoryId]);

  const onPageChange = (e) => {
    setPage(e);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const { data, isLoading, status, isError, error } = useQuery(
    ['hotels', categoryId, page],
    () => fetchData(categoryId)
    // { keepPreviousData: true }
  );

  const filteredCategories = data?.categoryData?.filter((category) => {
    return category?.node?._meta?.uid !== categoryId;
  });

  return (
    <>
      {categoryId !== undefined ? (
        <SEO
          title={categoryData?.meta_title?.[0]?.text}
          description={categoryData?.meta_description?.[0]?.text}
          keywords={categoryData?.meta_keywords?.[0]?.text}
        />
      ) : (
        <SEO
          title='Cool Unique Hotels for Retreats and Weekend Trips | Luxpitality'
          description='Book individual and group retreats online at the coolest unique hotels on Luxpitality. Find the perfect place to stay for your upcoming weekend trips!'
          keywords={'retreats'}
        />
      )}
      <NavigationBar search />

      {data?.response?.results?.length !== 0 && (
        <section className='result-info'>
          <motion.div
            className='result-info__location-sort'
            variants={hiddenBlockServices}
            initial='hidden'
            animate='visible'
          >
            <motion.span variants={tituloVariant} className='place'>
              Featured Hotels
            </motion.span>
          </motion.div>
        </section>
      )}

      <motion.div variants={container} initial='hidden' animate='visible'>
        {status === 'loading' ? (
          <>
            <Spin size='large' tip='Loading...' />
          </>
        ) : data?.response?.results ? (
          data?.response?.results?.map((hotel) => (
            <HotelCard key={hotel.id} hotel={hotel} />
          ))
        ) : (
          data?.response?.map((hotel) => (
            <HotelCard key={hotel?.hotels?._meta?.id} hotel={hotel} />
          ))
        )}
      </motion.div>

      {data?.response?.results?.length !== 0 && (
        <div className='pagination'>
          {data?.response?.results && (
            <Pagination
              current={page}
              total={data?.response?.total_results_size}
              onChange={(e) => onPageChange(e)}
            />
          )}
        </div>
      )}
      <section className='categories-section'>
        <h2>Make it unforgettable. Make it Luxpitality.</h2>
        {status === 'loading' ? (
          <>
            <Spin size='large' tip='Loading...' />
          </>
        ) : (
          <div>
            {isLoading ? null : data?.response?.results?.length === 0 ? (
              <p className='categories-section_title'>
                Oops! This adventure is unavailable right now. Why don't you try
                another?
              </p>
            ) : (
              <p className='categories-section_title'>
                Looking for another type of adventure? Try one of these:
              </p>
            )}

            <div
              className={
                categoryId
                  ? 'categories-section__items-container'
                  : 'categories-section__items-container-2'
              }
            >
              {filteredCategories?.map((category) => {
                return (
                  <div key={category?.node?._meta?.uid}>
                    <motion.div
                      variants={children}
                      whileHover='hover'
                      className='item'
                    >
                      <span>
                        <img
                          src={category?.node?.background?.url}
                          alt={category?.node?.background?.alt}
                        />
                      </span>
                      <span>
                        <Link
                          to={`/featured-hotels/${category?.node?._meta?.uid}`}
                        >
                          {category.node.name}
                        </Link>
                      </span>
                    </motion.div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};
export default HotelList;
